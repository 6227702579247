import _buffer from "buffer";
import _stream from "stream";
import _util from "util";
import _bufferStream from "./buffer-stream";
var exports = {};
const {
  constants: BufferConstants
} = _buffer;
const stream = _stream;
const {
  promisify
} = _util;
const bufferStream = _bufferStream;
const streamPipelinePromisified = promisify(stream.pipeline);

class MaxBufferError extends Error {
  constructor() {
    super("maxBuffer exceeded");
    this.name = "MaxBufferError";
  }

}

async function getStream(inputStream, options) {
  if (!inputStream) {
    throw new Error("Expected a stream");
  }

  options = {
    maxBuffer: Infinity,
    ...options
  };
  const {
    maxBuffer
  } = options;
  const stream = bufferStream(options);
  await new Promise((resolve, reject) => {
    const rejectPromise = error => {
      // Don't retrieve an oversized buffer.
      if (error && stream.getBufferedLength() <= BufferConstants.MAX_LENGTH) {
        error.bufferedData = stream.getBufferedValue();
      }

      reject(error);
    };

    (async () => {
      try {
        await streamPipelinePromisified(inputStream, stream);
        resolve();
      } catch (error) {
        rejectPromise(error);
      }
    })();

    stream.on("data", () => {
      if (stream.getBufferedLength() > maxBuffer) {
        rejectPromise(new MaxBufferError());
      }
    });
  });
  return stream.getBufferedValue();
}

exports = getStream;

exports.buffer = (stream, options) => getStream(stream, { ...options,
  encoding: "buffer"
});

exports.array = (stream, options) => getStream(stream, { ...options,
  array: true
});

exports.MaxBufferError = MaxBufferError;
export default exports;
export const buffer = exports.buffer,
      array = exports.array;
const _MaxBufferError = exports.MaxBufferError;
export { _MaxBufferError as MaxBufferError };