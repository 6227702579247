import _stream from "stream";
import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
const {
  PassThrough: PassThroughStream
} = _stream;

exports = options => {
  options = { ...options
  };
  const {
    array
  } = options;
  let {
    encoding
  } = options;
  const isBuffer = encoding === "buffer";
  let objectMode = false;

  if (array) {
    objectMode = !(encoding || isBuffer);
  } else {
    encoding = encoding || "utf8";
  }

  if (isBuffer) {
    encoding = null;
  }

  const stream = new PassThroughStream({
    objectMode
  });

  if (encoding) {
    stream.setEncoding(encoding);
  }

  let length = 0;
  const chunks = [];
  stream.on("data", chunk => {
    chunks.push(chunk);

    if (objectMode) {
      length = chunks.length;
    } else {
      length += chunk.length;
    }
  });

  stream.getBufferedValue = () => {
    if (array) {
      return chunks;
    }

    return isBuffer ? Buffer.concat(chunks, length) : chunks.join("");
  };

  stream.getBufferedLength = () => length;

  return stream;
};

export default exports;